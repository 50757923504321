<template>
  <div>
    <el-dialog
      title="Are you sure you want to approve the request?"
      :visible.sync="localVisible"
      @close="closeDialog"
    >
      <div>
        <div class="u-bottom-margin--2x">
          <el-form :rules="rules" :model="dialogFormData">
            <template>
              <div>
                <el-form-item
                  :label="`WholeSaler name`"
                  prop="name"
                  label-width="160px"
                >
                  <el-input
                    v-model="dialogFormData.name"
                    autocomplete="off"
                    :disabled="true"
                  >
                  </el-input>
                </el-form-item>
                <div style="display: flex">
                  <el-form-item
                    :label="`Start Date`"
                    prop="start_date"
                    label-width="160px"
                    style="flex-basis: 50%"
                  >
                    <el-date-picker
                      v-model="dialogFormData.start_date"
                      type="datetime"
                      placeholder="Select date and time"
                      value-format="timestamp"
                      style="width: 100%"
                      :disabled="true"
                    >
                    </el-date-picker>
                  </el-form-item>

                  <el-form-item
                    label-width="160px"
                    :label="`End Date`"
                    prop="end_date"
                    style="flex-basis: 50%"
                  >
                    <el-date-picker
                      v-model="dialogFormData.end_date"
                      type="datetime"
                      placeholder="Select date and time"
                      value-format="timestamp"
                      style="width: 100%"
                    >
                    </el-date-picker>
                  </el-form-item>
                </div>
              </div>
            </template>
          </el-form>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">{{ $t("GlobalsCancelBtn") }}</el-button>
        <el-button type="success" @click="ApproveVacation">{{
          $t("GlobalsSaveBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { wholesalersAvailabilityService } from "@/services/wholesalersAvailability.service.js";
import { utils } from "@/utils";
export default {
  name: "ApproveWHSAvailabilityDialog",
  props: {
    rowData: {
      required: true,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localVisible: false,
      dialogFormData: null,
      originalData: null,
      rules: {},
      defaultCreateData: {
        key: null,
        role: null,
        name: null,
      },
    };
  },
  watch: {
    showDialog(value) {
      this.localVisible = value;
      if (value === false) {
        this.dialogFormData = JSON.parse(
          JSON.stringify(this.defaultCreateData)
        );
      }
    },
    rowData(newVal) {
      if (newVal) {
        this.originalData = JSON.parse(JSON.stringify(newVal));
        this.dialogFormData = { ...newVal };
      } else {
        this.dialogFormData = JSON.parse(
          JSON.stringify(this.defaultCreateData)
        );
        this.originalData = null;
      }
    },
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
      this.dialogFormData = null;
      this.originalData = null;
    },
    async ApproveVacation() {
      try {
        let apiObject = {
          endDate: this.dialogFormData.end_date,
          action: "accepted",
        };
        let rowId = this.dialogFormData.entity_id;

        const response =
          await wholesalersAvailabilityService.requestApproveVacation(
            rowId,
            apiObject
          );
        if (response) {
          utils.popupMessageWrapper(
            "This operation is Successfully",
            "success",
            1500
          );
          this.$emit("approveSucceed");
        }
      } catch (error) {
        const errorMessage = utils.errorMessageExtractor(error);
        utils.popupMessageWrapper(errorMessage, "error", 3500);
      }
    },
  },
};
</script>

<style></style>
