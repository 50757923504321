import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function fetchWholesalersAvailability(page = 1, filters) {
  return $http.get(`/admin/distributerSectionArea/vacation/logs`, {
    params: { page, filter: filters },
    headers: authHeader(),
  });
}

function requestApproveVacation(rowId, requestBody) {
  return $http.post(`/admin/distributerSectionArea/vacation/logs/wholesalers/${rowId}/action`, requestBody, {
    headers: authHeader(),
  });
}
function requestRejectVacation(rowId, requestBody) {
  return $http.post(
    `/admin/distributerSectionArea/vacation/logs/wholesalers/${rowId}/action`,
    requestBody,
    {
      headers: authHeader(),
    }
  );
}
export const wholesalersAvailabilityService = {
  fetchWholesalersAvailability,
  requestApproveVacation,
  requestRejectVacation,
};
