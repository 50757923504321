<template>
  <div>
    <template>
      <el-table :data="list" border stripe>
        <el-table-column prop="entity_id" label="Wholesaler ID" />
        <el-table-column prop="name" label="Wholesaler Name"> </el-table-column>
        <el-table-column prop="governorateName" label="Governorate">
        </el-table-column>
        <el-table-column prop="start_date" label="Start Date">
          <template slot-scope="prop">
            <div>{{ prop.row.start_date | moment("DD/MM/YYYY HH:mm") }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="end_date" label="End Date">
          <template slot-scope="prop">
            <div>{{ prop.row.end_date | moment("DD/MM/YYYY HH:mm") }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="hold_reason" label="Hold Reason">
        </el-table-column>
        <el-table-column prop="comment" label="Comment"> </el-table-column>
        <el-table-column label="Status" width="120">
          <template slot-scope="prop">
            <el-tag type="info">{{ prop.row.status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="operations" :label="$t('GlobalOperationsLabel')">
          <template slot-scope="props">
            <div>
              <el-button
                type="success"
                size="mini"
                @click="onClickApproveButton(props.row)"
              >
                {{ "Approve" }}
              </el-button>
              <el-button
                type="danger"
                size="mini"
                @click="onClickRejectButton(props.row)"
              >
                {{ "Reject" }}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="u-display-flex u-justify-content--center u-top-padding--2x">
        <el-pagination
          :hide-on-single-page="true"
          background
          layout="prev, pager, next"
          :current-page="paginationCurrentPage"
          :total="pagination.totalResultsCount"
          @current-change="handlePagination"
          :page-size="25"
        ></el-pagination>
      </div>
    </template>

    <ApproveWHSAvailabilityDialog
      :showDialog="showApproveModalOpen"
      :rowData="rowData"
      @closeDialog="onClickCloseApproveDialog"
      @approveSucceed="approveSucceed"
    />
  </div>
</template>

<script>
import { wholesalersAvailabilityService } from "@/services/wholesalersAvailability.service.js";
import ApproveWHSAvailabilityDialog from "./ApproveWHSAvailabilityDialog.vue";
import { utils } from "@/utils";

export default {
  name: "WholesalersAvailabilityPage",
  components: {
    ApproveWHSAvailabilityDialog,
  },

  data() {
    return {
      list: [],
      showCreateDialogFlag: false,
      originalData: [],
      updateErrorResponseObject: null,
      showApproveModalOpen: false,
      rowData: null,
      pagination: {},
    };
  },
  computed: {
    currentPage() {
      return this.$route.query.page;
    },
    paginationCurrentPage() {
      return this.pagination.nextPage
        ? this.pagination.nextPage - 1
        : this.pagination.totalPages;
    },
  },
  methods: {
    onClickApproveButton(object) {
      this.showApproveModalOpen = true;
      this.rowData = JSON.parse(JSON.stringify(object));
    },
    onClickCloseApproveDialog() {
      this.showApproveModalOpen = false;
      this.rowData = null;
    },
    async getWholesalersAvailabilityList(page = 1, chosenFilters) {
      const filters = chosenFilters;
      try {
        const response =
          await wholesalersAvailabilityService.fetchWholesalersAvailability(
            page,
            filters
          );
        let responseData = response.data.data?.logs;
        this.pagination = {
          totalPages: response.data.data.pages,
          totalResultsCount: response.data.data.pages * 25,
          nextPage: response.data.data.next,
        };

        this.originalData = JSON.parse(JSON.stringify(responseData));
        this.list = responseData;
        return response;
      } catch (error) {
        const errorMessage = utils.errorMessageExtractor(error);
        utils.popupMessageWrapper(errorMessage, "error", 3500);
      }
    },
    async approveSucceed() {
      this.showApproveModalOpen = false;
      this.rowData = null;
      this.getWholesalersAvailabilityList(this.currentPage, this.filters);
    },
    async onClickRejectButton(singleRow) {
      this.$confirm(`Are you sure to reject "${singleRow.name}" ?`, "Warning", {
        confirmButtonText: this.$t("GlobalOkBtn"),
        cancelButtonText: this.$t("GlobalCancelBtn"),
        type: "warning",
      }).then(async () => {
        try {
          let apiObject = { action: "rejected" };
          const response =
            await wholesalersAvailabilityService.requestRejectVacation(
              singleRow.entity_id,
              apiObject
            );
          if (response && response.status == 200) {
            this.$message({
              message: "This operation is Successfully",
              type: "success",
              duration: 1500,
            });

            await this.getWholesalersAvailabilityList(
              this.currentPage,
              this.filters
            );
          }
        } catch (error) {
          const errorMessage = utils.errorMessageExtractor(error);
          utils.popupMessageWrapper(errorMessage, "error", 3500);
        }
      });
    },
    handlePagination(val) {
      this.getWholesalersAvailabilityList(val, this.filters);
      this.$router.push({
        query: {
          page: val,
        },
      });
    },
  },
  async mounted() {
    this.getWholesalersAvailabilityList(this.currentPage, this.filters);
  },
};
</script>

<style lang="scss" scoped></style>
